<script>
export default {
  methods:{
    ignore(){
      this.$emit("ignoreForm");
    },
    submit(){
      this.$emit("submitForm");
    }
  }
}
</script>

<template>
  <CRow class="mt-3" align-horizontal="end">
    <CCol md="2">
      <CButton
        block
        color="primary"
        class="h-100"
        variant="outline"
        @click="ignore"
      >
        انصراف
      </CButton>
    </CCol>
    <CCol md="2">
      <CButton
        class="center-g-5 text-center"
        color="primary"
        block
        @click="submit"
      >
        <CIcon name="cil-check" size="lg"/>
        <span>ثبت</span>
      </CButton>
    </CCol>
  </CRow>
</template>

<style scoped>

</style>
